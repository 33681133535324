import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/Layout/layout"

import styled from 'styled-components'

const Div = styled.div`    
.page-not-found {
  position: relative;
  margin-top: 10%;
}
h2{
  position: relative;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 96px;
  line-height: 144px;
  color: var(--textcolor);
}
h6{
  position: relative;
  font-family: 'Barlow Semi Condensed';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  color: var(--textcolor);
  text-align: center;
}
p{
  position: relative;
  font-family: 'Barlow Semi Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: var(--admin-hover);
  margin-bottom: 10%;
}
`
export default function NotFound() {
  return (
    <Layout>
      <Div className="container d-flex justify-content-center align-items-center flex-column py-5">
        <StaticImage
          src="../images/404.png"
          alt="Page Not Found"
          layout="constrained"
          width={372}
          height={136}
          loading="eager"
          className="page-not-found"
        />
        <div className="container d-flex justify-content-center align-items-center flex-column">
          <h2>404</h2>
          <h6>Sorry, The page not found</h6>
          <p>The link you followed probably broken or the page has removed.</p>
        </div>
      </Div>
    </Layout>
  )
}
